import React from 'react';
import './Cali.css'; 

const Apiay = () => {
  return (
   <div>
    <div className='Espacio-titulo'>
      <h1><a className='Titulo' href="/aip/SKAP.pdf" target="_blank" rel="noreferrer">Base aérea Apiay</a></h1>
    </div>

    <div className='containeraeropuertos'>
      
      <div className='subcontaineraeropuertos'>
        <h1>SID</h1>
        <div className='container-tablas'>
          <table className='tabla-aeropuertos1'>
          <tr className='divisoraeropuertos1'>
              <th className="pista">PISTA 10</th>                      
          </tr>  
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/VVC2J.pdf" target="_blank" rel="noreferrer">VVC2J</a></th>
          </tr>    
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/VVC2J.pdf" target="_blank" rel="noreferrer">VVC1K</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/VVC2J.pdf" target="_blank" rel="noreferrer">VVC1L</a></th>
          </tr>  
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ROPO2Z.pdf" target="_blank" rel="noreferrer">ROPO2W</a></th>
          </tr>  
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/VVC2J.pdf" target="_blank" rel="noreferrer">UBRI2W</a></th>
          </tr> 
          </table>
          <table className='tabla-aeropuertos2'>
          <tr className='divisoraeropuertos1'>
              <th className="pista">PISTA 28</th>                      
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/VVC2J.pdf" target="_blank" rel="noreferrer">VVC2J</a></th>
          </tr>    
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/VVC2J.pdf" target="_blank" rel="noreferrer">VVC1K</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/VVC2J.pdf" target="_blank" rel="noreferrer">VVC1L</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ROPO2Z.pdf" target="_blank" rel="noreferrer">ROPO2Z</a></th>
          </tr>  
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/VVC2J.pdf" target="_blank" rel="noreferrer">ROPO2Y</a></th>
          </tr> 
          </table>
        </div> 
      </div>

      <div className='subcontaineraeropuertos'>
        <h1>STAR</h1>
        <div className='container-tablas'>
          <table className='tabla-aeropuertos1'>
          <tr className='divisoraeropuertos2'>
              <th className="pista">PISTA 10</th>                      
          </tr>
          </table>

          <table className='tabla-aeropuertos2'>
          <tr className='divisoraeropuertos2'>
              <th className="pista">PISTA 28</th>                      
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ANLA2Z.pdf" target="_blank" rel="noreferrer">ANLA2Z</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ANLA2Z.pdf" target="_blank" rel="noreferrer">POVK2Z</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ANLA2Z.pdf" target="_blank" rel="noreferrer">KOTE2Z</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ANLA2Z.pdf" target="_blank" rel="noreferrer">UBNO2Z</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ANLA2Z.pdf" target="_blank" rel="noreferrer">UBRI2Z</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/UBNO2Y.pdf" target="_blank" rel="noreferrer">UBNO2Y</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/UBNO2Y.pdf" target="_blank" rel="noreferrer">POVK2Y</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/UBNO2Y.pdf" target="_blank" rel="noreferrer">KOTE1Y</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/UBNO2Y.pdf" target="_blank" rel="noreferrer">UBRI2Y</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ANLA2X.pdf" target="_blank" rel="noreferrer">ANLA2X</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ANLA2X.pdf" target="_blank" rel="noreferrer">UBNO2X</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ANLA2X.pdf" target="_blank" rel="noreferrer">POVK2X</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ANLA2X.pdf" target="_blank" rel="noreferrer">KOTE2X</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ANLA2X.pdf" target="_blank" rel="noreferrer">UBRI2X</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ANLA2X.pdf" target="_blank" rel="noreferrer">ROPO2X</a></th>
          </tr>
          </table>
        </div> 
      </div>

      <div className='subcontaineraeropuertos'>
        <h1>IAC</h1>
        <div className='container-tablas'>
           <table className='tabla-aeropuertos1'>
          <tr className='divisoraeropuertos3'>
              <th className="pista">PISTA 10</th>                      
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/VORA10.pdf" target="_blank" rel="noreferrer">VOR A</a></th>
          </tr>
          </table>
          <table className='tabla-aeropuertos2'>
          <tr className='divisoraeropuertos3'>
              <th className="pista">PISTA 28</th>                      
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ILSZ28.pdf" target="_blank" rel="noreferrer">ILS Z</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ILSZ28.pdf" target="_blank" rel="noreferrer">LOC Z</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/ILSY28.pdf" target="_blank" rel="noreferrer">ILS Y</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/RNP28.pdf" target="_blank" rel="noreferrer">RNP</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKAP/VOR28.pdf" target="_blank" rel="noreferrer">VOR</a></th>
          </tr>
          </table>
        </div>
      </div>

    </div>

  </div>
  );
};
export default Apiay;