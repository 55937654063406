import React from 'react';
import './Cali.css'; 

const Palanquero = () => {
  return (
   <div>
    <div className='Espacio-titulo'>
      <h1><a className='Titulo' href="/aip/SKPQ.pdf" target="_blank" rel="noreferrer">Base aérea Palanquero</a></h1>
    </div>

    <div className='containeraeropuertos'>
      
      <div className='subcontaineraeropuertos'>
        <h1>SID</h1>
        <div className='container-tablas'>
          <table className='tabla-aeropuertos1'>
          <tr className='divisoraeropuertos1'>
              <th className="pista">PISTA 01</th>                      
          </tr>  
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/ISNI1B.pdf" target="_blank" rel="noreferrer">ISNI1B</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/VUNR1B.pdf" target="_blank" rel="noreferrer">VUNR1B</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/PAL1A.pdf" target="_blank" rel="noreferrer">PAL1A</a></th>
          </tr>
          </table>
          <table className='tabla-aeropuertos2'>
          <tr className='divisoraeropuertos1'>
              <th className="pista">PISTA 19</th>                      
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/PAL1A.pdf" target="_blank" rel="noreferrer">PAL1A</a></th>
          </tr>
          </table>
        </div> 
      </div>

      <div className='subcontaineraeropuertos'>
        <h1>STAR</h1>
        <div className='container-tablas'>
          <table className='tabla-aeropuertos1'>
          <tr className='divisoraeropuertos2'>
              <th className="pista">PISTA 01</th>                      
          </tr>
         
          </table>

          <table className='tabla-aeropuertos2'>
          <tr className='divisoraeropuertos2'>
              <th className="pista">PISTA 19</th>                      
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/ATAN1R.pdf" target="_blank" rel="noreferrer">ATAN1R</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/GEVB1A.pdf" target="_blank" rel="noreferrer">GEVB1A</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/MQU1R.pdf" target="_blank" rel="noreferrer">MQU1R</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/MUGO1R.pdf" target="_blank" rel="noreferrer">MUGO1R</a></th>
          </tr>
          </table>
        </div> 
      </div>

      <div className='subcontaineraeropuertos'>
        <h1>IAC</h1>
        <div className='container-tablas'>
           <table className='tabla-aeropuertos1'>
          <tr className='divisoraeropuertos3'>
              <th className="pista">PISTA 01</th>                      
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/CIRCULAR01.pdf" target="_blank" rel="noreferrer">CIRCULAR</a></th>
          </tr>
          </table>
          <table className='tabla-aeropuertos2'>
          <tr className='divisoraeropuertos3'>
              <th className="pista">PISTA 19</th>                      
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/ILSZ19.pdf" target="_blank" rel="noreferrer">ILS Z</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/ILSY19.pdf" target="_blank" rel="noreferrer">ILS Y</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/ILSY19.pdf" target="_blank" rel="noreferrer">LOC Y</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/ILSX19.pdf" target="_blank" rel="noreferrer">ILS X</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/ILSW19.pdf" target="_blank" rel="noreferrer">ILS W</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/ILSW19.pdf" target="_blank" rel="noreferrer">LOC W</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/VORZ19.pdf" target="_blank" rel="noreferrer">VOR Z</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/VORY19.pdf" target="_blank" rel="noreferrer">VOR Y</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKPQ/VORV19.pdf" target="_blank" rel="noreferrer">VOR V</a></th>
          </tr>
          </table>
        </div>
      </div>

    </div>

  </div>
  );
};
export default Palanquero;