import React from 'react';
import './Cucuta.css'; 

const Marandua = () => {
  return (
    <div>
     <div className='Espacio-titulo'>
     <h1><a className='Titulo' href="/aip/SKCC.pdf" target="_blank" rel="noreferrer">Base aérea Marandua</a></h1>
     </div>
 
     <div className='containeraeropuertos'>
       
       <div className='subcontainerCUC'>
         <h1>SID</h1>
         <div className='container-tablas'>
           <table className='tabla-aeropuertos1'>
           <tr className='divisoraeropuertos1'>
               <th className="pista">PISTA 07</th>                      
           </tr>  
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/TIDS1B.pdf" target="_blank" rel="noreferrer">TIDS1B</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/TIDS1B.pdf" target="_blank" rel="noreferrer">LUTVB</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/TIDS1B.pdf" target="_blank" rel="noreferrer">AMVO1B</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/TIDS1B.pdf" target="_blank" rel="noreferrer">KUBT1A</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/TIDS1B.pdf" target="_blank" rel="noreferrer">OVTU1B</a></th>
           </tr>
          
           </table>
           <table className='tabla-aeropuertos2'>
           <tr className='divisoraeropuertos1'>
               <th className="pista">PISTA 25</th>                      
           </tr>           
         
           </table>
         </div> 
       </div>
 
       <div className='subcontainerCUC'>
         <h1>STAR</h1>
         <div className='container-tablas'>
           <table className='tabla-aeropuertos1'>
           <tr className='divisoraeropuertos2'>
               <th className="pista">PISTA 07</th>                      
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/LUTV1A.pdf" target="_blank" rel="noreferrer">LUTV1A</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/LUTV1A.pdf" target="_blank" rel="noreferrer">AMVO1A</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/LUTV1A.pdf" target="_blank" rel="noreferrer">TIDS1A</a></th>
           </tr>
           
           
           </table>
 
           <table className='tabla-aeropuertos2'>
           <tr className='divisoraeropuertos2'>
               <th className="pista">PISTA 25</th>                      
           </tr>
           
           </table>
         </div> 
       </div>
 
       <div className='subcontainerCUC'>
         <h1>IAC</h1>
         <div className='container-tablas'>
            <table className='tabla-aeropuertos1'>
           <tr className='divisoraeropuertos3'>
               <th className="pista">PISTA 07</th>                      
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/ILSY07.pdf" target="_blank" rel="noreferrer">ILS Y</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/ILSY07.pdf" target="_blank" rel="noreferrer">LOC Y</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/ILSZ07.pdf" target="_blank" rel="noreferrer">ILS Z</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/ILSZ07.pdf" target="_blank" rel="noreferrer">LOC Z</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/RNP07.pdf" target="_blank" rel="noreferrer">RNP</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/VORZ07.pdf" target="_blank" rel="noreferrer">VOR Z</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/VORY07.pdf" target="_blank" rel="noreferrer">VOR Y</a></th>
           </tr>
           
           
           </table>
           <table className='tabla-aeropuertos2'>
           <tr className='divisoraeropuertos3'>
               <th className="pista">PISTA 25</th>                      
           </tr> 
           
           </table>
         </div>
       </div>
 
     </div>
 
     <div className='containeraeropuertos'>
       
       <div className='subcontainerCUC'>
         <h1>SID</h1>
         <div className='container-tablas'>
           <table className='tabla-aeropuertos1'>
           <tr className='divisoraeropuertos1'>
               <th className="pista">PISTA 03</th>                      
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/TIDS1C.pdf" target="_blank" rel="noreferrer">TIDS1C</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/TIDS1C.pdf" target="_blank" rel="noreferrer">OVTU1A</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/TIDS1C.pdf" target="_blank" rel="noreferrer">LUTV1C</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/TIDS1C.pdf" target="_blank" rel="noreferrer">AMVO1C</a></th>
           </tr>
           
           </table>
           <table className='tabla-aeropuertos2'>
           <tr className='divisoraeropuertos1'>
               <th className="pista">PISTA 21</th>                      
           </tr>
           
           </table>
         </div> 
       </div>
 
       <div className='subcontainerCUC'>
         <h1>STAR</h1>
         <div className='container-tablas'>
           <table className='tabla-aeropuertos1'>
           <tr className='divisoraeropuertos2'>
               <th className="pista">PISTA 03</th>                      
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/LUTV1A.pdf" target="_blank" rel="noreferrer">LUTV1A</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/LUTV1A.pdf" target="_blank" rel="noreferrer">AMVO1A</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/LUTV1A.pdf" target="_blank" rel="noreferrer">TIDS1A</a></th>
           </tr>
           </table>
 
           <table className='tabla-aeropuertos2'>
           <tr className='divisoraeropuertos2'>
               <th className="pista">PISTA 21</th>                      
           </tr>
         
           </table>
         </div> 
       </div>
 
       <div className='subcontainerCUC'>
         <h1>IAC</h1>
         <div className='container-tablas'>
            <table className='tabla-aeropuertos1'>
           <tr className='divisoraeropuertos3'>
               <th className="pista">PISTA 03</th>                      
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/VORZ03.pdf" target="_blank" rel="noreferrer">VOR Z</a></th>
           </tr>
           <tr>
             <th className='Procedimientos'><a href="/regionales/SKUA/VORY03.pdf" target="_blank" rel="noreferrer">VOR Y</a></th>
           </tr>
           </table>
           <table className='tabla-aeropuertos2'>
           <tr className='divisoraeropuertos3'>
               <th className="pista">PISTA 21</th>                      
           </tr> 
          
           
           </table>
         </div>
       </div>
 
     </div>
 
   </div>
   );
};
export default Marandua;