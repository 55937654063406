import React from 'react';
import './Cali.css'; 

const Tresesquinas = () => {
  return (
   <div>
    <div className='Espacio-titulo'>
      <h1><a className='Titulo' href="/aip/SKTQ.pdf" target="_blank" rel="noreferrer">Base aérea Tres esquinas</a></h1>
    </div>

    <div className='containeraeropuertos'>
      
      <div className='subcontaineraeropuertos'>
        <h1>SID</h1>
        <div className='container-tablas'>
          <table className='tabla-aeropuertos1'>
          <tr className='divisoraeropuertos1'>
              <th className="pista">PISTA 07</th>                      
          </tr>  
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ANSU2D.pdf" target="_blank" rel="noreferrer">ANSU2D</a></th>
          </tr>    
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ANSU2D.pdf" target="_blank" rel="noreferrer">REPE3A</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ANSU2D.pdf" target="_blank" rel="noreferrer">VAKI1G</a></th>
          </tr>  
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ESBOM3A.pdf" target="_blank" rel="noreferrer">ESBOM3A</a></th>
          </tr>  
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ESBOM3A.pdf" target="_blank" rel="noreferrer">POVPI2A</a></th>
          </tr> 
          </table>
          <table className='tabla-aeropuertos2'>
          <tr className='divisoraeropuertos1'>
              <th className="pista">PISTA 25</th>                      
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ANSU2D.pdf" target="_blank" rel="noreferrer">ANSU2D</a></th>
          </tr>    
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ANSU2D.pdf" target="_blank" rel="noreferrer">REPE3A</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ANSU2D.pdf" target="_blank" rel="noreferrer">VAKI1G</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ESBOM3A.pdf" target="_blank" rel="noreferrer">ESBOM3A</a></th>
          </tr>  
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ESBOM3A.pdf" target="_blank" rel="noreferrer">POVPI2A</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/FLA1A.pdf" target="_blank" rel="noreferrer">FLA1A</a></th>
          </tr> 
          </table>
        </div> 
      </div>

      <div className='subcontaineraeropuertos'>
        <h1>STAR</h1>
        <div className='container-tablas'>
          <table className='tabla-aeropuertos1'>
          <tr className='divisoraeropuertos2'>
              <th className="pista">PISTA 07</th>                      
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/POVPI1B.pdf" target="_blank" rel="noreferrer">POVPI1B</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/POVPI1B.pdf" target="_blank" rel="noreferrer">KORNU1C</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/POVPI1B.pdf" target="_blank" rel="noreferrer">VAKIM1D</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE3D.pdf" target="_blank" rel="noreferrer">REPE3D</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE3D.pdf" target="_blank" rel="noreferrer">ANSU3D</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE2F.pdf" target="_blank" rel="noreferrer">REPE2F</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE2F.pdf" target="_blank" rel="noreferrer">UTGU2D</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE2F.pdf" target="_blank" rel="noreferrer">ANSU2E</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE2F.pdf" target="_blank" rel="noreferrer">KORN1E</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE2F.pdf" target="_blank" rel="noreferrer">VAKI1F</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE2F.pdf" target="_blank" rel="noreferrer">ESBO1C</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE2F.pdf" target="_blank" rel="noreferrer">POVP2C</a></th>
          </tr> 
          </table>

          <table className='tabla-aeropuertos2'>
          <tr className='divisoraeropuertos2'>
              <th className="pista">PISTA 25</th>                      
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/POVPI1B.pdf" target="_blank" rel="noreferrer">POVPI1B</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/POVPI1B.pdf" target="_blank" rel="noreferrer">KORNU1C</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/POVPI1B.pdf" target="_blank" rel="noreferrer">VAKIM1D</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE2B.pdf" target="_blank" rel="noreferrer">REPE2B</a></th>
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE2B.pdf" target="_blank" rel="noreferrer">ANSU2A</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE2B.pdf" target="_blank" rel="noreferrer">KORN2A</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE2B.pdf" target="_blank" rel="noreferrer">VAKI1H</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPEG2C.pdf" target="_blank" rel="noreferrer">REPEG2C</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPEG2C.pdf" target="_blank" rel="noreferrer">ANSUG2B</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPEG2C.pdf" target="_blank" rel="noreferrer">KORNU2B</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPEG2C.pdf" target="_blank" rel="noreferrer">VAKIM2C</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE1E.pdf" target="_blank" rel="noreferrer">REPE1E</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE1E.pdf" target="_blank" rel="noreferrer">ANSU1F</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE1E.pdf" target="_blank" rel="noreferrer">KORN1D</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE1E.pdf" target="_blank" rel="noreferrer">VAKI1E</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE1E.pdf" target="_blank" rel="noreferrer">ESBO1B</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE1E.pdf" target="_blank" rel="noreferrer">POVP1D</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/REPE1E.pdf" target="_blank" rel="noreferrer">UTGU2C</a></th>
          </tr>
          </table>
        </div> 
      </div>

      <div className='subcontaineraeropuertos'>
        <h1>IAC</h1>
        <div className='container-tablas'>
           <table className='tabla-aeropuertos1'>
          <tr className='divisoraeropuertos3'>
              <th className="pista">PISTA 07</th>                      
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/VOR07.pdf" target="_blank" rel="noreferrer">VOR</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/RNP07.pdf" target="_blank" rel="noreferrer">RNP</a></th>
          </tr>
          </table>
          <table className='tabla-aeropuertos2'>
          <tr className='divisoraeropuertos3'>
              <th className="pista">PISTA 25</th>                      
          </tr> 
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/VORX25.pdf" target="_blank" rel="noreferrer">VOR X</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/VORY25.pdf" target="_blank" rel="noreferrer">VOR Y</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/VORZ25.pdf" target="_blank" rel="noreferrer">VOR Z</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ILSZ25.pdf" target="_blank" rel="noreferrer">ILS Z</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ILSZ25.pdf" target="_blank" rel="noreferrer">LOC Z</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ILSY25.pdf" target="_blank" rel="noreferrer">ILS Y</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/ILSY25.pdf" target="_blank" rel="noreferrer">LOC Y</a></th>
          </tr>
          <tr>
            <th className='Procedimientos'><a href="/regionales/SKTQ/RNP25.pdf" target="_blank" rel="noreferrer">RNP</a></th>
          </tr>
          </table>
        </div>
      </div>

    </div>

  </div>
  );
};
export default Tresesquinas;